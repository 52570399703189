<template>
  <v-list nav>
    <v-list-item-group color="info">
      <v-list-item
        v-for="(menu, index) in menus"
        :key="index"
        :to="{ path: menu.path }"
      >
        <v-list-item-icon>
          <v-icon>{{ menu.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ menu.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "MenuDashboard",

  data() {
    return {};
  },

  computed: {
    menus() {
      return [
        {
          name: "Home",
          path: "/admin/home",
          icon: "mdi-home",
        },
         {
          name: "Auditoria",
          path: "/admin/audits",
          icon: "mdi-cast-audio-variant",
        },
        {
          name: "Perfis",
          path: "/admin/perfis",
          icon: "mdi-account",
        },
         
        {
          name: "Permissões",
          path: "/admin/permissoes",
          icon: "mdi-account-lock",
        },
        {
          name: "Usuarios",
          path: "/admin/usuarios",
          icon: "mdi-account-group",
        },
      
      ];
    },
  },
};
</script>

<style lang="scss"></style>
